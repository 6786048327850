import React, { useState, useEffect } from "react";
import supabase from "../../../supabase";

function Manage({ addAbbreviation, setCurrentSelection }) {
  const [abbreviation, setAbbreviation] = useState("");
  const [definition, setDefinition] = useState("");
  const [description, setDescription] = useState("");
  const [otherInformation, setOtherInformation] = useState("");
  const [tags, setTags] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    const tagsArray = tags.split(",").map((tag) => tag.trim());

    const { data: newAcronym, error } = await supabase
      .from("abbreviations_list")
      .insert([
        {
          abbreviation,
          definition,
          description,
          otherInformation,
          tags: tagsArray,
        },
      ])
      .select();

    if (error) {
      console.error(error);
    } else {
      addAbbreviation(newAcronym[0]);
      setAbbreviation("");
      setDefinition("");
      setDescription("");
      setOtherInformation("");
      setTags("");
    }
  }

  return (
    <div className="input-form">
      <h2 className="section-title">Contribute an abbreviation</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <span className="input-name">Abbreviation*:</span>
          <input
            className="input-field"
            name="abbreviation"
            type="text"
            placeholder="SNDGO"
            value={abbreviation}
            onChange={(e) => setAbbreviation(e.target.value)}
          />
        </div>
        <div className="form-row">
          <span className="input-name">Definition*:</span>
          <input
            className="input-field"
            type="text"
            placeholder="Smart Nation and Digital Governance Office"
            value={definition}
            onChange={(e) => setDefinition(e.target.value)}
          />
        </div>
        <div className="form-row">
          <span className="input-name">Description:</span>
          <input
            className="input-field"
            type="text"
            placeholder="Government agency responsible for advancing Singapore's smart nation and digital governance initiatives."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="form-row">
          <span className="input-name">Tags</span>
          <input
            className="input-field"
            type="text"
            placeholder="government agency, digital governance"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>
        <button
          className="submit-button"
          disabled={!abbreviation || !definition}
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Manage;
