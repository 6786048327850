import React from "react";

function CardDescription({ abbreviations }) {
  const { abbreviation, definition, description } = abbreviations;
  return (
    <div>
      <h2>{abbreviation}</h2>
      <h1>{definition}</h1>
      <p>{description}</p>
    </div>
  );
}

export default CardDescription;
