import React from "react";
import SearchBar from "./search/SearchBar";
import TagBar from "./search/TagBar";
import Card from "./card/Card";
import FullList from "./fullList/FullList";
import Manage from "./manage/Manage";

function MainBody({
  currentSelection,
  setCurrentSelection,
  searchTerm,
  setSearchTerm,
  searchTag,
  setSearchTag,
  abbreviations,
  addAbbreviation,
  setEditAbbreviation,
  editAbbreviation,
}) {
  const sortedAbbreviations = abbreviations.sort((a, b) =>
    a.abbreviation.localeCompare(b.abbreviation)
  );

  const filteredAbbreviations = sortedAbbreviations.filter((abbreviation) => {
    const matchesAbbreviation = abbreviation.abbreviation
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const tags = abbreviation.tags || [];
    const searchTags = searchTag
      .split(",")
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag.length > 0);

    const matchesTag =
      searchTags.length === 0 ||
      searchTags.every((searchTag) =>
        tags.some((tag) => tag.toLowerCase().includes(searchTag))
      );

    return matchesAbbreviation && matchesTag;
  });

  return (
    <div>
      {currentSelection === "Search" && (
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      )}
      {currentSelection === "Search" && (
        <TagBar searchTag={searchTag} setSearchTag={setSearchTag} />
      )}
      {currentSelection === "Search" &&
        filteredAbbreviations.map((abbr) => (
          <Card
            key={abbr.id}
            setCurrentSelection={setCurrentSelection}
            abbreviations={abbr}
            setEditAbbreviation={setEditAbbreviation}
          />
        ))}
      {currentSelection === "Manage" && (
        <Manage
          setCurrentSelection={setCurrentSelection}
          addAbbreviation={addAbbreviation}
          editAbbreviation={editAbbreviation}
          setEditAbbreviation={setEditAbbreviation}
        />
      )}
    </div>
  );
}

export default MainBody;
