import React, { useState } from "react";
import Logo from "./Logo";

function Navigation({ currentSelection, setCurrentSelection }) {
  function selectSection(value) {
    setCurrentSelection(value);
  }

  return (
    <div>
      <Logo />
      <div className="navigation">
        <button
          onClick={() => selectSection("Search")}
          className={currentSelection === "Search" ? "selected" : ""}
          value="Search"
        >
          Search
        </button>
        {/* <button
        onClick={() => selectSection("All")}
        className={currentSelection === "All" ? "selected" : ""}
        value="All"
      >
        View All
      </button> */}
        <button
          onClick={() => selectSection("Manage")}
          className={currentSelection === "Manage" ? "selected" : ""}
          value="Manage"
        >
          Contribute
        </button>
      </div>
    </div>
  );
}

export default Navigation;
