import React, { useState } from "react";

function SearchBar({ searchTerm, setSearchTerm }) {
  const [inputValue, setInputValue] = useState(searchTerm);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          className="search"
          type="text"
          placeholder="🔍 Search for an acronym"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </form>
      {/* <div className="button-container">
        <button className="search-button">Search</button>
        <button className="search-button">Random</button>
      </div> */}
    </div>
  );
}

export default SearchBar;
