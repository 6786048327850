import React, { useState, useEffect } from "react";
import Navigation from "./header/Navigation";
import MainBody from "./body/MainBody";
import Footer from "./footer/Footer";
import supabase from "../supabase";

function App() {
  const [currentSelection, setCurrentSelection] = useState("Search");
  const [abbreviations, setAbbreviations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [editAbbreviation, setEditAbbreviation] = useState(null);

  useEffect(function () {
    async function getAbbreviations() {
      const { data: abbreviations, error } = await supabase
        .from("abbreviations_list")
        .select("*");
      setAbbreviations(abbreviations);
    }
    getAbbreviations();
  }, []);

  const addAbbreviation = (newAbbreviation) => {
    setAbbreviations((prevAbbreviations) => [
      newAbbreviation,
      ...prevAbbreviations,
    ]);
  };

  return (
    <div className="container">
      <Navigation
        currentSelection={currentSelection}
        setCurrentSelection={setCurrentSelection}
      />
      <MainBody
        currentSelection={currentSelection}
        setCurrentSelection={setCurrentSelection}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchTag={searchTag}
        setSearchTag={setSearchTag}
        abbreviations={abbreviations}
        addAbbreviation={addAbbreviation}
        editAbbreviation={editAbbreviation}
        setEditAbbreviation={setEditAbbreviation}
      />
      <Footer />
    </div>
  );
}

export default App;
