import React, { useState } from "react";

function TagBar({ searchTag, setSearchTag }) {
  const [inputValue, setInputValue] = useState(searchTag);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setSearchTag(e.target.value);
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          className="search"
          type="text"
          placeholder="🏷 Search by categories"
          value={inputValue}
          onChange={handleInputChange}
        />
      </form>
    </div>
  );
}

export default TagBar;
