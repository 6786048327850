import React from "react";
import CardDescription from "./CardDescription";
import CardTag from "./CardTag";

function Card({ setCurrentSelection, abbreviations, setEditAbbreviation }) {
  const handleEditClick = () => {
    setEditAbbreviation(abbreviations);
    setCurrentSelection("Manage");
  };
  return (
    <div className="info-card">
      <CardDescription abbreviations={abbreviations} />
      <div className="tag-container">
        {abbreviations.tags &&
          abbreviations.tags.map((tag, index) => (
            <CardTag key={index} tag={tag} />
          ))}
      </div>
    </div>
  );
}

export default Card;
