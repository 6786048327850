import React from "react";

function Footer() {
  return (
    <div className="footnote">
      Disclaimer: This website is created for learning purposes only. The
      information provided here should not be considered professional advice.
      Please note that we make no guarantees regarding the accuracy,
      completeness, or reliability of the contents of this website. Any actions
      you take based on the contents of this website are at your own risk. We
      are not liable for any losses or damages incurred from the use of this
      website.
    </div>
  );
}

export default Footer;
